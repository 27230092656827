import React from 'react';
import {
    MenuItem,
    TextField,
    Grid,
    Chip,
  } from "@mui/material";

function Grid206({handler}, {edificio}) {
  return (
    <div>
        <Grid item xs={3}>
            <TextField
              label="Edifício"
              variant="outlined"
              value={edificio}
              style={{ minWidth: 130, maxWidth: 150 }}
              select
              onChange={handler}
              SelectProps={{
                multiple: false,
                renderValue: (selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <Chip key={selected} label={selected} style={{ margin: 2 }} />
                  </div>
                ),
              }}
            >
                <MenuItem value={'A'}>A</MenuItem>
                <MenuItem value={'B'}>B</MenuItem>
                <MenuItem value={'F'}>F</MenuItem>
                <MenuItem value={'J'}>J</MenuItem>
                <MenuItem value={'K'}>K</MenuItem>
            </TextField>
          </Grid>

    </div>
  )
}

export default Grid206