import axios from 'axios'

export const getFiltered = async (param) => {
        const {especialidade, issuetype, status, endereco, bloco, relator, requestType, project} = param;

        const startAt = 0;
        const maxResults = 100;  
        const url = "https://backend-engemega-23a46f4fdfbc.herokuapp.com/sigeos/cloud/search/";
         //const url = "http://localhost:8093/sigeos/cloud/search/";

        var query = "('Request Type' = 'Abrir Ordem de Serviço (C01)' OR 'Request Type' = 'Abrir Ordem de Serviço (C02)')  ";
        
        if(especialidade != ''){
            if(especialidade.length > 1) {
                query=query.concat(" AND ( ");
                for(var j = 0 ; j < especialidade.length ; j++) {
                    if (j == 0) {
                        query=query.concat("Especialidades = '"+especialidade[j] + "'");
                    } else {
                        query=query.concat(" OR Especialidades = '"+especialidade[j] + "'");
                    }
                    
                }
                query=query.concat(" ) ");
            } else {
                query=query.concat(" AND Especialidades = '"+ especialidade.toString() + "'");
            }
        }

        if(issuetype != ''){
            if(issuetype.length > 1) {
                query=query.concat(" AND ( ");
                for(var j = 0 ; j < issuetype.length ; j++) {
                    if (j == 0) {
                        query=query.concat("issuetype = '"+issuetype[j] + "'");
                    } else {
                        query=query.concat(" OR issuetype = '"+issuetype[j] + "'");
                    }
                    
                }
                query=query.concat(" ) ");
            } else {
                query=query.concat(" AND issuetype = '"+ issuetype.toString() + "'");
            }
        }

        if (project != '') {
            console.log('testesasassdasdaddasdsddsdsadsdasdasdasd')
            console.log(project)
            if (project.length > 1) {
                query=query.concat(" AND ( ");
                for (var j = 0 ; j < requestType.length ; j++) {
                    if(j === 0) {
                        query=query.concat("'project' = '"+project+"'");
                    } else {
                        query=query.concat(" OR 'project' = '"+project + "'")
                    }
                }
                query=query.concat(")");
            } else {
                query=query.concat(" AND 'project' = '"+ project.toString() + "'");
            }
        }


        if(status != ''){
            if(status.length > 1) {
                query=query.concat(" AND ( ");
                for(var j = 0 ; j < status.length ; j++) {
                    if (j == 0) {
                        query=query.concat("status = '"+status[j] + "'");
                    } else {
                        query=query.concat(" OR status = '"+status[j] + "'");
                    }
                    
                }
                query=query.concat(" ) ");
            } else {
                query=query.concat(" AND status = '"+ status.toString() + "'");
            }
        }

        if(endereco != ''){
            query = query.concat( " AND Endereço = '" + endereco + "'" );
           if(bloco != ''){
                switch(endereco){
                    case 'SQN 205':
                        query = query.concat(" AND 'BLOCOS SQN 205' = '" + bloco + "'");
                        break;
                    case 'SQN 206':
                        query = query.concat(" AND 'BLOCOS SQN 206' = '" + bloco + "'");
                        break;
                    case 'SQN 109':
                        query = query.concat(" AND 'BLOCOS SQN 109' = '" + bloco + "'");
                        break;
                    case 'SQN 212':
                        query = query.concat(" AND 'BLOCOS SQN 212' = '" + bloco + "'");
                        break;
                    case 'SQN 214':
                        query = query.concat(" AND 'BLOCOS SQN 214' = '" + bloco + "'");
                        break;
                    case 'SQN 310':
                        query = query.concat(" AND 'BLOCOS SQN 310' = '" + bloco + "'");
                        break;
                    case 'COLINA':
                        query = query.concat(" AND 'BLOCOS COLINA' = '" + bloco + "'");
                        break;
                    case 'LAKE SIDE':
                        query = query.concat(" AND 'BLOCOS LAKE SIDE' = '" + bloco + "'");
                        break;
                    case 'SCLN 406':
                        query = query.concat(" AND 'BLOCOS SCLN 406' = '" + bloco + "'");
                        break;
                    case 'CASA DE NIEMEYER':
                        query = query.concat(" AND 'BLOCOS CASA DE NIEMEYER' = '" + bloco + "'");
                        break;
                   case 'ED. ANÁPOLIS':
                       query = query.concat(" AND 'BLOCOS ED. ANÁPOLIS' = '" + bloco + "'");
                       break;
                  case 'ED. OK':
                           query = query.concat(" AND 'BLOCOS ED. OK' = '" + bloco + "'");
                           break;
                   default: break;
                }
           }
        }
        if (relator != '') {
            query = query.concat( " AND reporter = '" + relator + "'" );
        }

        query = query.concat(" order by created asc")
        console.log(query)
    return await axios.post(url, {jql:query, startAt:startAt, maxResults:maxResults});
}


export const getOsFiltered  = async (param) => {
    if (param.shift == '' && param.start == '' && param.end == ''  && param.muliple == '' && param.issuetype == ''){
        return null;
    } else {
        var query = "status = Planejado";

        if (param.shift != '') {
            query=query.concat(" AND Turno = " + param.shift);
        }
        if (param.start != '') {
            query=query.concat(" AND 'Start date' >= " + param.start);
        }
        if (param.end != '') {
            query=query.concat(" AND 'End date' <= " +param.end);
        }
        if(param.issuetype != ''){
            if(param.issuetype.length > 1) {
                query=query.concat(" AND ( ");
                for(var j = 0 ; j < param.issuetype.length ; j++) {
                    if (j == 0) {
                        query=query.concat("issuetype = "+param.issuetype[j]);
                    } else {
                        query=query.concat(" OR issuetype = "+param.issuetype[j]);
                    }
                    
                }
                query=query.concat(" ) ");
            } else {
                query=query.concat(" AND issuetype = "+ param.issuetype.toString());
            }
        }
        if(param.multiple != ''){
            console.log(param.multiple.length);
            if(param.multiple.length > 1) {
                /**
                 * 2 
                 * Civil,Elétrica
                 * AND ( Equipe = Civil OR Equipe = Elétrica )
                 * 
                 * 3
                 * Civil,Elétrica,TI
                 * AND ( Equipe = Civil OR Equipe = Elétrica OR Equipe = TI )
                 */
                //console.log(param.multiple);
                 query=query.concat(" AND ( ");
                for(var i = 0 ; i < param.multiple.length ; i++) {
                    if (i == 0) {
                        query=query.concat("Equipe = "+param.multiple[i]);
                    } else {
                        query=query.concat(" OR Equipe = "+param.multiple[i]);
                    }
                    
                }
                 query=query.concat(" ) ");
            }else {
                console.log(param.multiple.toString());
                query=query.concat(" AND Equipe = "+ param.multiple.toString());
            }
            
        }

        query=query.concat(" order by 'Start Date'");

        console.log("DEBUG  " + query);

        const data = {
            jql: query,
        }

        var config = {
            method: 'post',
            url: 'http://rj.sigeos.com.br/rest/api/2/search',
            headers: {
                'Authorization': 'Basic YWRtaW46bWt0YjIwMTU=', 
                'Content-Type': 'application/json'
            },
            data: data,
        };
        return axios(config);
    }
}