import React, { useContext, useEffect } from 'react'
import './styles.css'
import { IssueContext } from '../../contexts/IssueContext';

function Impressao() {

    const {issue, setIssue} = useContext(IssueContext)

    function dateHandler(issue) {
        if (issue.fields.created != null) {
            let date = new Date(issue.fields.created);
            return date.toLocaleDateString();
        }
    }

    useEffect(() => {
    },[])


    function blocosHandler(issue) {
        if (issue.fields.customfield_10068 != null) {
            return issue.fields.customfield_10068.value;
        }
        if (issue.fields.customfield_10069 != null) {
            return issue.fields.customfield_10069.value;
        }
        if (issue.fields.customfield_10064 != null) {
            return issue.fields.customfield_10064.value;
        }
        if (issue.fields.customfield_10062 != null) {
            return issue.fields.customfield_10062.value;
        }
        if (issue.fields.customfield_10063 != null) {
            return issue.fields.customfield_10063.value;
        }
        if (issue.fields.customfield_10065 != null) {
            return issue.fields.customfield_10065.value;
        }
        if (issue.fields.customfield_10066 != null) {
            return issue.fields.customfield_10066.value;
        }
        if (issue.fields.customfield_10067 != null) {
            return issue.fields.customfield_10067.value;
        }
    }

    function edificiosHandler(issue) {
        if (issue.fields.customfield_10073 != null) {
            return issue.fields.customfield_10073.value;
        } else {
            if (issue.fields.customfield_10074 != null) {
                return issue.fields.customfield_10074.value;
            } else {
                if (issue.fields.customfield_10075 != null) {
                    return issue.fields.customfield_10075.value;
                } else {
                    if (issue.fields.customfield_10076 != null) {
                        return issue.fields.customfield_10076.value;
                    } else {
                        if (issue.fields.customfield_10077 != null) {
                            return issue.fields.customfield_10077.value;
                        } else {
                            if (issue.fields.customfield_10078 != null) {
                                return issue.fields.customfield_10078.value;
                            } else {
                                if (issue.fields.customfield_10079 != null) {
                                    return issue.fields.customfield_10079.value;
                                } else {
                                    if (issue.fields.customfield_10080 != null) {
                                        return issue.fields.customfield_10080.value;
                                    } else {
                                        if (issue.fields.customfield_10081 != null) {
                                            return issue.fields.customfield_10081.value;
                                        }
                                        if (issue.fields.customfield_10082 != null) {
                                            return issue.fields.customfield_10082.value;
                                        } else {
                                            if (issue.fields.customfield_10083 != null) {
                                                return issue.fields.customfield_10083.value;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    

    function descriptionHandler(issue) {
        console.log('verificar descrição')
        console.log(issue.fields.description?.content)
        let desc = '';
        
        console.log(issue.fields.description?.content) //array primario
        for (var i = 0; i < issue?.fields.description.content.length; i++) {
            //Navegação do array primario

            console.log(issue?.fields.description.content[i])
            if (issue?.fields.description.content[i].type === 'orderedList') {
                console.log(issue?.fields.description.content[i])
                console.log('entrei1231')
                console.log(issue?.fields.description.content[i].content)
                console.log('entrei1231999999999999')
                //pegar o total de listItems
                const qtdListItems = issue?.fields.description.content[i].content.length
                for (var j = 0; j < qtdListItems; j++) {
                    console.log('for interno')
                    console.log(issue?.fields.description.content[i].content[j]?.content[0].content[0].text)
                    desc = desc + '. ' + issue?.fields.description.content[i].content[j]?.content[0].content[0].text
                }
            }

            console.log('esasdkljdjslkhjsdfhs')
            console.log(issue?.fields.description.content[i].type)
            if (issue?.fields.description.content[i].type === 'paragraph') {
                console.log('paragrafo')
                console.log(issue?.fields.description.content[i].content[0]?.text)
                desc = desc + '. ' + issue?.fields.description.content[i].content[0]?.text
            }

            //desc = desc + '. ' + issue?.fields.description.content[i].content[0]?.text
        }
        return desc;
    }

    const issue2 = {
        "id": "15659",
        "key": "UNB-5649",
        "fields": {
            "issuetype": {
                "name": "Corretiva"
            },
            "status": {
                "name": "AGUARDANDO APROVAÇÃO ORÇAMENTO"
            },
            "summary": "Pressão da água nas descargas estão muito fortes no PJC",
            "created": "2023-11-01T13:04:32.758-0300",
            "reporter": {
                "self": "https://unbsigeos.atlassian.net/rest/api/3/user?accountId=qm%3A1a28f9c1-213b-4188-9754-56ea4e02cf69%3Aa7318969-984a-4d65-b684-1b248883d357",
                "accountId": "qm:1a28f9c1-213b-4188-9754-56ea4e02cf69:a7318969-984a-4d65-b684-1b248883d357",
                "emailAddress": "humbertofarias@unb.br",
                "displayName": "Humberto Barbosa Farias",
                "active": "true",
                "timeZone": "America/Sao_Paulo",
                "accountType": "customer"
            },
            "customfield_10064": {
                "self": "https://unbsigeos.atlassian.net/rest/api/3/customFieldOption/10110",
                "value": "Hidráulica",
                "id": "10110"
            },
            "customfield_10084": "JOAQUIM ARNOLDO PIMENTEL PINHEIRO",
            "customfield_10085": "999629328",
            "customfield_10086": "COORDENAÇÃO DE GESTÃO DOS ESPAÇOS COMUNS",
            "customfield_10069": null,
            "customfield_10083": null,
            "customfield_10071": {
                "self": "https://unbsigeos.atlassian.net/rest/api/3/customFieldOption/10122",
                "value": "DARCY GLEBA A",
                "id": "10122"
            },
            "customfield_10073": null,
            "customfield_10074": null,
            "customfield_10075": {
                "self": "https://unbsigeos.atlassian.net/rest/api/3/customFieldOption/10349",
                "value": "PJC",
                "id": "10349"
            },
            "customfield_10076": null,
            "customfield_10077": null,
            "customfield_10078": null,
            "customfield_10079": null,
            "customfield_10080": null,
            "customfield_10081": null,
            "customfield_10082": "PRC",
            "customfield_10092": "2023-11-06T13:04:00.000-0300",
            "customfield_10093": null,
            "customfield_10094": null,
            "customfield_10095": null,
            "customfield_10072": null,
            "customfield_10070": null,
            "customfield_10091": {
                "self": "https://unbsigeos.atlassian.net/rest/api/3/customFieldOption/10475",
                "value": "MANUTENÇÃO DE ÁREAS COMUNS DA UNB",
                "id": "10475"
            },
            "description": {
                "version": 1,
                "type": "doc",
                "content": [
                    {
                        "type": "paragraph",
                        "content": [
                            {
                                "type": "text",
                                "text": "Requisição SIPAC: 11336/2023"
                            }
                        ]
                    },
                    {
                        "type": "paragraph",
                        "content": [
                            {
                                "type": "text",
                                "text": "A pressão da água nas descargas estão muito fortes."
                            }
                        ]
                    },
                    {
                        "type": "paragraph",
                        "content": [
                            {
                                "type": "text",
                                "text": "Horário de Atendimento: 8:00 ás 18:00hs"
                            }
                        ]
                    }
                ]
            },
        }
    }

    function especialidadeHandler(issue) {
        if (issue.fields.customfield_10071 != null) {
            
        console.log(issue.fields.customfield_10071[0].value)
            return issue.fields.customfield_10071[0].value;
        } else {
            return "";
        }
    }

    return (
        <>
            {(issue ? (
                <><div className='header'>
                <div>
                    <img src="./assets/viewavatar.png" alt="UnB" width='80' />
                </div>
                <div>
                    <h3>ORDEM DE SERVIÇO</h3>
                </div>
                <div>&nbsp;</div>
            </div>

            <div className='osdata'>
                <span><strong>Número:</strong> {issue?.key}</span>
                <span><strong>Data:</strong> {dateHandler(issue)}</span>
                <span><strong>Endereço: </strong> {issue?.fields.customfield_10061?.value}</span>
                <span><strong>Bloco: </strong>{blocosHandler(issue)}</span>
                <span><strong>Apto / Sala / Loja: </strong>{issue?.fields.customfield_10070}</span>
                <span><strong>Morador: </strong>{issue?.fields.customfield_10078}</span>
                <span><strong>Contato Morador: </strong>{issue?.fields.customfield_10079}</span>
                <img src='./assets/Image_002.png' height='2' style={{margin: '10px'}}/>
                <span><strong>Especialidade: </strong>{especialidadeHandler(issue)}</span>
                <span><strong>Resumo: </strong>{issue?.fields.summary}</span>
                <span><strong>Descrição: </strong>{descriptionHandler(issue)}</span>
                <img src='./assets/Image_002.png' height='2' style={{margin: '10px'}}/>
                <h3 className='subTitle'>
                    Execução
                </h3>
                <div className='executores'>
                    <span><strong>Início:  </strong> ___/___/_____    ___:___</span>
                    <span><strong>Términio:</strong> ___/___/_____    ___:___</span>
                    <span><strong>Executores:</strong></span>
                </div>
                <img src='./assets/Image_002.png' height='2' style={{margin: '10px'}}/>
                <h3 className='subTitle'>Ateste:</h3>
                <div className='ateste'>
                    <div className='execucao'>
                        <span><strong>Executado:</strong> </span> <span><img src='./assets/image_004.png' /> Concluída </span> <span><img src='./assets/image_004.png' /> Não executada </span> <span><img src='./assets/image_004.png' /> Parcial </span>
                    </div>
                    <div className='data'>
                        <span>Após cumprida a presente Ordem de Serviço, faço a devolução do serviço executado. </span>
                        <span>Em ___/___/____</span>
                    </div>
                </div>

                <div className='footer'>
                    <div>
                        <span>_____________________________</span>
                        <br />
                        <span>Responsável pelo Serviço</span>
                    </div>
                    <div>
                        <span>___________________________</span>
                        <br />
                        <span>Solicitante Responsável</span>
                    </div>
                </div>
            </div></>
            ) : console.log(issue))}
            
        </>
    )

}

export default Impressao;