import { Container } from '@mui/material';
import './App.css';
import Filtros from './components/Filtros';
import Impressao from './components/Impressao/Impressao';
import { BrowserRouter } from 'react-router-dom'
import { Router } from './Router'
import { IssueProvider } from './contexts/IssueContext';
import React  from 'react';

function App() {
  return (
    <BrowserRouter>
      <IssueProvider>
        <Container maxWidth="xl" className="descer">
          <Router />
        </Container>
      </IssueProvider>
    </BrowserRouter>
  );
}

export default App;
