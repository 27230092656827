import {
    MenuItem,
    Paper,
    TextField,
    Grid,
    Chip,
} from "@mui/material";
import React, {useState, useEffect, useContext} from "react";
import {getFiltered} from "../api/api";
import TablePage from "./TablePage";
import GridAlto from "./Grid/GridAlto";
import GridColina from "./Grid/GridColina";
import GridLake from "./Grid/GridLake";
import Grid109 from "./Grid/Grid109";
import Grid205 from "./Grid/Grid205";
import Grid206 from "./Grid/Grid206";
import Grid212 from "./Grid/Grid212";
import Grid214 from "./Grid/Grid214";
import Grid310 from "./Grid/Grid310";
import Loading from "./Loading";
import Autocomplete from '@mui/material/Autocomplete';
import {IssueContext} from "../contexts/IssueContext";

function Filtros() {
    const {issue, setIssue} = useContext(IssueContext)

    const customers = [
        
        {label: 'miriam.braga@unb.br'},
        {label: 'francely.pereira@unb.br'},
        {label: 'lorena.rocha@unb.br'},
        {label: 'henriquepires@unb.br'},
        {label: 'rvelloso@unb.br'},
        {label: 'fabricio.jonathan@unb.br'},
        {label: 'david.nunes@unb.br'},
        {label: 'marcos.henrique@construturaengemega.com.br'},
        {label: 'bruna@construturaengemega.com.br'},
        {label: 'pedro.soares@construtoraengemega.com.br'},
    ]


    const [form, setForm] = useState({
        especialidade: [],
        issuetype: [],
        status: [],
        project: [],
        requestType: [],
        endereco: '',
        bloco: '',
        relator: ''

    });
    const [list, setList] = useState([]);

    const handleStatus = (event) => {
        setForm({...form, status: event.target.value});
    };
    const handleIssuetype = (event) => {
        setForm({...form, issuetype: event.target.value});
    };
    const handleRequestType = (event) => {
        setForm({...form, requestType: event.target.value});
    }
    const handleProject = (event) => {
        setForm({...form, project: event.target.value});
    }
    const handleEspecialidade = (event) => {
        setForm({...form, especialidade: event.target.value});
    };
    const handleEndereco = (event) => {
        setForm({...form, endereco: event.target.value});
    };
    const handleBloco = (event) => {
        setForm({...form, bloco: event.target.value});
    };
    const handleRelator = (event, newValue) => {
        console.log(newValue.label);
        setForm({...form, relator: newValue.label});
    };
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getFiltered(form)
            .then((result) => {
                setList(result.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });


    }, [form]);

    return (
        <>
        <Paper elevation={3}>
            <Grid
                className="esquerda"
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                columns={{xs: 4, sm: 8, md: 12}}
            >
                <Grid item xs={3}>
                    <TextField
                        label="Especialidade"
                        variant="outlined"
                        value={form.especialidade}
                        style={{minWidth: 130}}
                        select
                        onChange={handleEspecialidade}
                        SelectProps={{
                            multiple: true,
                            renderValue: (selected) => (
                                <div style={{display: "flex", flexWrap: "wrap"}}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} style={{margin: 2}}/>
                                    ))}
                                </div>
                            ),
                        }}
                    >

                        <MenuItem value={"Alvernaria"}>Alvenaria</MenuItem>
                        <MenuItem value={"Elétrica"}>Elétrica</MenuItem>
                        <MenuItem value={"Fibra Óptica"}>Fibra Óptica</MenuItem>
                        <MenuItem value={"Hidráulica"}>Hidráulica</MenuItem>
                        <MenuItem value={"Impermeabilização"}>Impermeabilização</MenuItem>
                        <MenuItem value={"Limpeza e Conservação"}>Limpeza e Conservação</MenuItem>
                        <MenuItem value={"Marcenaria"}>Marcenaria</MenuItem>
                        <MenuItem value={"Pintura"}>Pintura</MenuItem>
                        <MenuItem value={"Serralheria"}>Serralheria</MenuItem>
                        <MenuItem value={"Telecomunicações"}>Telecomunicações</MenuItem>
                        <MenuItem value={"Vidraçaria"}>Vidraçaria</MenuItem>
                        <MenuItem value={"Manutenção geral"}>Manutenção Geral</MenuItem>
                    </TextField>
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        label="Contrato"
                        variant="outlined"
                        value={form.project}
                        style={{minWidth: 130}}
                        select
                        onChange={handleProject}
                        SelectProps={{
                            multiple: true,
                            renderValue: (selected) => (
                                <div style={{display: "flex", flexWrap: "wrap"}}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} style={{margin: 2}}/>
                                    ))}
                                </div>
                            ),
                        }}
                    >
                        <MenuItem value={"1223 - 2022"}>1223 - 2022</MenuItem>
                        <MenuItem value={"1206 - 2024"}>1206 - 2024</MenuItem>
                    </TextField>
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        label="Status"
                        variant="outlined"
                        value={form.status}
                        style={{minWidth: 130}}
                        select
                        onChange={handleStatus}
                        SelectProps={{
                            multiple: true,
                            renderValue: (selected) => (
                                <div style={{display: "flex", flexWrap: "wrap"}}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} style={{margin: 2}}/>
                                    ))}
                                </div>
                            ),
                        }}
                    >
                        {
                            //status in (Agendado, "Aguardando Envio para a Empresa", "Aguardando Morador", "Análise ENGEMEGA", "Análise Fiscalização", Concluído, "Contato Sem Sucesso", Disponível, 
                            //"Em Aprovação de Orçamento", "Em Execução", "Em Observação", "Emergência Atendida", Emergêncial, 
                            //"Execução Emergêncial", "Levantamento de Serviço", Medido, Orçamento, "Orçamento Aprovado", "Serviço Executado", "Somente Vistoria") order by created DESC
                        }
                        <MenuItem value={"Agendado"}>Agendado</MenuItem>
                        <MenuItem value={"Aguardando Envio para a Empresa"}>Aguardando Envio para a Empresa</MenuItem>
                        <MenuItem value={"Aguardando Morador"}>Aguardando Morador</MenuItem>
                        <MenuItem value={"Análise ENGEMEGA"}>Análise ENGEMEGA</MenuItem>
                        <MenuItem value={"Análise Fiscalização"}>Análise Fiscalização</MenuItem>
                        <MenuItem value={"Concluído"}>Concluído</MenuItem>
                        <MenuItem value={"Contato Sem Sucesso"}>Contato Sem Sucesso</MenuItem>
                        <MenuItem value={"Disponível"}>Disponível</MenuItem>
                        <MenuItem value={"Em Aprovação de Orçamento"}>Em Aprovação de Orçamento</MenuItem>
                        <MenuItem value={"Em Execução"}>Em Execução</MenuItem>
                        <MenuItem value={"Em Observação"}>Em Observação</MenuItem>
                        <MenuItem value={"Emergência Atendida"}>Emergência Atendida</MenuItem>
                        <MenuItem value={"Emergêncial"}>Emergêncial</MenuItem>
                        <MenuItem value={"Execução Emergêncial"}>Execução Emergêncial</MenuItem>
                        <MenuItem value={"Levantamento de Serviço"}>Levantamento de Serviço</MenuItem>
                        <MenuItem value={"Medido"}>Medido</MenuItem>
                        <MenuItem value={"Orçamento"}>Orçamento</MenuItem>
                        <MenuItem value={"Orçamento Aprovado"}>Orçamento Aprovado</MenuItem>
                        <MenuItem value={"Serviço Executado"}>Serviço Executado</MenuItem>
                        <MenuItem value={"Somente Vistoria"}>Somente Vistoria</MenuItem>
                    </TextField>
                </Grid>

                <Autocomplete
                    disablePortal
                    id="relator"
                    options={customers}
                    sx={{width: 300}}

                    onChange={handleRelator}
                    renderInput={(params) => <TextField {...params} label="Relator"/>}
                />


                <Grid item xs={3}>
                    <TextField
                        label="Endereço"
                        variant="outlined"
                        value={form.endereco}
                        style={{minWidth: 130, maxWidth: 150}}
                        select
                        onChange={handleEndereco}
                        SelectProps={{
                            multiple: false,
                            renderValue: (selected) => (
                                <div style={{display: "flex", flexWrap: "wrap"}}>

                                    <Chip key={selected} label={selected} style={{margin: 2}}/>

                                </div>
                            ),
                        }}
                    >
                        <MenuItem value={"SQN 205"}>SQN 205</MenuItem>
                        <MenuItem value={"SQN 206"}>SQN 206</MenuItem>
                        <MenuItem value={"SQN 109"}>SQN 109</MenuItem>
                        <MenuItem value={"SQN 212"}>SQN 212</MenuItem>
                        <MenuItem value={"SQN 214"}>SQN 214</MenuItem>
                        <MenuItem value={"SQN 310"}>SQN 310</MenuItem>
                        <MenuItem value={"COLINA"}>COLINA</MenuItem>
                        <MenuItem value={"LAKE SIDE"}>LAKE SIDE</MenuItem>
                        <MenuItem value={"SCLN 406"}>SCLN 406</MenuItem>
                        <MenuItem value={"CASA DE NIEMEYER"}>CASA DE NIEMEYER</MenuItem>
                        <MenuItem value={"ED. ANÁPOLIS"}>ED. ANÁPOLIS</MenuItem>
                        <MenuItem value={"ED. OK"}>ED. OK</MenuItem>
                    </TextField>
                </Grid>

              {form.endereco === 'SQN 205' && <Grid205 handler={handleBloco} bloco={form.bloco} /> }
              {form.endereco === 'SQN 206' && <Grid206 handler={handleBloco} bloco={form.bloco} /> }
              {form.endereco === 'SQN 109' && <Grid109 handler={handleBloco} bloco={form.bloco} /> }
              {form.endereco === 'SQN 212' && <Grid212 handler={handleBloco} bloco={form.bloco} /> }
              {form.endereco === 'SQN 214' && <Grid214 handler={handleBloco} bloco={form.bloco} /> }
              {form.endereco === 'SQN 310' && <Grid310 handler={handleBloco} bloco={form.bloco} /> }
              {form.endereco === 'COLINA' && <GridColina handler={handleBloco} bloco={form.bloco} /> }
              {form.endereco === 'LAKE SIDE' && <GridLake handler={handleBloco} bloco={form.bloco} /> }
              
        </Grid>
        </Paper>

    <br/>
    <br/>
    <br/>
    <div className="center">
        {loading && <Loading/>}

    </div>
    <TablePage dados={list}/>
</>
)
    ;


}


    export default Filtros;
