
import React, { useContext, useEffect } from 'react'
import Paper from '@mui/material/Paper';
import { Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { format } from 'date-fns'
import { IssueContext } from '../contexts/IssueContext';
import { useNavigate } from 'react-router-dom';

function TablePage(dados) {

    const { issue, setIssue } = useContext(IssueContext)
    const navigate = useNavigate()

    useEffect(() => {
        //console.log(dados);
    }, [dados]);

    function localHandler(issue) {
        if (issue.customfield_10078 != null) {
            return issue.customfield_10078.value;
        } else {
            if (issue.customfield_10079 != null) {
                return issue.customfield_10079.value;
            } else {
                if (issue.customfield_10080 != null) {
                    return issue.customfield_10080.value;
                } else {
                    if (issue.customfield_10081 != null) {
                        return issue.customfield_10081.value;
                    } else {
                        if (issue.customfield_10082 != null) {
                            return issue.customfield_10082.value
                        } else {
                            return "";
                        }
                    }
                }
            }
        }
    }

    function blocosHandler(issue) {
        if (issue.fields.customfield_10068 != null) {
            return issue.fields.customfield_10068.value;
        }
        if (issue.fields.customfield_10069 != null) {
            return issue.fields.customfield_10069.value;
        }
        if (issue.fields.customfield_10064 != null) {
            return issue.fields.customfield_10064.value;
        }
        if (issue.fields.customfield_10062 != null) {
            return issue.fields.customfield_10062.value;
        }
        if (issue.fields.customfield_10063 != null) {
            return issue.fields.customfield_10063.value;
        }
        if (issue.fields.customfield_10065 != null) {
            return issue.fields.customfield_10065.value;
        }
        if (issue.fields.customfield_10066 != null) {
            return issue.fields.customfield_10066.value;
        }
        if (issue.fields.customfield_10067 != null) {
            return issue.fields.customfield_10067.value;
        }
    }

    function enderecoHandler(issue) {
        if (issue.fields.customfield_10061 != null) {
            return issue.fields.customfield_10061.value;
        } else {
            return "";
        }
    }

    function especialidadeHandler(issue) {
        if (issue.fields.customfield_10071 != null) {
            
        // console.log(issue.fields.customfield_10071[0].value)
            return issue.fields.customfield_10071[0].value;
        } else {
            return "";
        }
    }

    function dateHandler(issue) {
        if (issue.fields.created != null) {
            let date = new Date(issue.fields.created);
            return date.toLocaleDateString();
        }
    }

    function handleClick(issue) {
        console.log('issue')
        console.log(issue)
        setIssue(issue)
        setTimeout(() => {
            navigate('/imprimir')
        }, 2000);
        
    }

    function classNames(issue) {
        //Case Azul
        if (issue.fields.status.name === 'Disponível' || issue.fields.status.name === 'Orçamento Aprovado' || 
            issue.fields.status.name === 'Aguardando Envio para a Empresa') {
            return (<strong className='badge-azul'>{issue.fields.status.name}</strong>)
        }

        //Case Amarelo
        if (issue.fields.status.name === "Contato Sem Sucesso" || issue.fields.status.name === "Aguardando Morador" || issue.fields.status.name === "Análise Fiscalização" 
            || issue.fields.status.name === "Análise ENGEMEGA" || issue.fields.status.name === "Em Observação") {
            return (<strong className='badge-amarelo'>{issue.fields.status.name}</strong>)
        }

        //Case Cinza
        if (issue.fields.status.name === 'Em Aprovação de Orçamento') {
            return (<strong className='badge-cinza'>{issue.fields.status.name}</strong>)
        }

        //Case Roxo
        if (issue.fields.status.name === 'Somente Vistoria' || issue.fields.status.name === 'Serviço Executado' || issue.fields.status.name === 'Concluído') {
            return (<strong className='badge-roxo'>{issue.fields.status.name}</strong>)
        }

        //Case Vermelho
        if (issue.fields.status.name === 'Emergêncial' || issue.fields.status.name === 'Execução Emergêncial' ) {
            return (<strong className='badge-vermelho'>{issue.fields.status.name}</strong>)
        }

        //Case Laranja
        if (issue.fields.status.name === 'Emergência Atendida') {
            return (<strong className='badge-laranja'>{issue.fields.status.name}</strong>)
        }

        //Case Preto
        if (issue.fields.status.name === 'Medido') {
            return (<strong className='badge-preto'>{issue.fields.status.name}</strong>)
        }

        //Case Verde
        if (issue.fields.status.name === 'Levantamento de Serviço' || issue.fields.status.name === 'Orçamento' || issue.fields.status.name === 'Agendado' || 
            issue.fields.status.name === 'Em Execução') {
            return (<strong className='badge-verde'>{issue.fields.status.name}</strong>)
        }

    }

    return (
        <TableContainer component={Paper} elevation={20}>
            <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow >
                        <TableCell>Data</TableCell>
                        <TableCell align="right">Contrato</TableCell>
                        <TableCell align="right">Nº OS</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right">Resumo</TableCell>
                        <TableCell align="right">Especialidade</TableCell>
                        <TableCell align="right">Endereço</TableCell>
                        <TableCell align="right">Bloco</TableCell>
                        <TableCell align="right">Relator</TableCell>
                        <TableCell align="right">Imprimir OS</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dados.dados.map((issue) => (
                        <>
                            <TableRow
                                key={issue.key}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">{dateHandler(issue)}</TableCell>
                                <TableCell align="right">{issue.fields.project.name}</TableCell>
                                <TableCell align="right">{issue.key}</TableCell>
                                <TableCell align="right">{classNames(issue)}</TableCell>
                                <TableCell align="right">
                                    <Link underline="none" href={`https://sigeos-engemega.atlassian.net/servicedesk/customer/portal/1/${issue.key}`}>
                                        {issue.fields.summary}
                                    </Link>
                                </TableCell>
                                <TableCell align="right">{especialidadeHandler(issue)}</TableCell>
                                <TableCell align="right">{enderecoHandler(issue)}</TableCell>
                                <TableCell align="right">{blocosHandler(issue)}</TableCell>
                                <TableCell align="right">{issue.fields.reporter.displayName}</TableCell>
                                <TableCell align="right">
                                    <button onClick={(()=> handleClick(issue))}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height='20' width='20' viewBox="0 0 512 512">
                                            <path d="M128 0C92.7 0 64 28.7 64 64v96h64V64H354.7L384 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0H128zM384 352v32 64H128V384 368 352H384zm64 32h32c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32H64v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384zM432 248a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></svg>
                                    </button>
                                </TableCell>

                            </TableRow>
                        </>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TablePage